<nz-input-group nzSearch [nzPrefix]="prefixUserIcon" [nzSuffix]="spin">
  <input
    style="margin-top: 1px"
    type="text"
    placeholder="Número de celular..."
    nz-input
    yappyPhoneSearch
    (yappyOnLoadingChange)="isLoading = $event"
  />
</nz-input-group>
<ng-template #prefixUserIcon>
  <i nz-icon nzType="user" nzTheme="outline"></i>
</ng-template>
<ng-template #spin
  ><i
    color="primary"
    nz-icon
    nzType="loading"
    nzSize="large"
    *ngIf="isLoading"
  ></i
></ng-template>
