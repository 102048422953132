<mat-table
  cdkScrollable
  [dataSource]="(clients$ | async) || []"
  matSort
  matSortDisableClear
  [matSortActive]="(sorting$ | async).field"
  [matSortDirection]="(sorting$ | async).sort?.toLowerCase()"
  (matSortChange)="handleSortChange($event)"
>
  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef> NOMBRE </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.firstName + " " + element.lastName }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef> NÚMERO DEL CELULAR </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.phone }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef> ESTADO </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <img
        [src]="clientsModelViewUtil.getStatusIndicatorUrl(element.status)"
        alt="Status de baja"
        style="margin-right: 6px"
      />
      {{ clientsModelViewUtil.getStatusWord(element.status) }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dateOfLastModification">
    <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      ÚLTIMA MODIFICACIÓN
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.dateOfLastModification | date: "d / MMMM / y" }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="entemis">
    <mat-header-cell *matHeaderCellDef> ENTEMIS </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.entemis }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> VER MÁS </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      style="cursor: pointer"
      (click)="handleItemClick(element.id)"
    >
      <img
        src="assets/subtract.png"
        alt="Go to user detail"
        style="margin-left: 13px"
      />
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<div
  class="no-users-found"
  *ngIf="!(isClientsDataBeingLoaded$ | async) && !(clients$ | async).length"
>
  <img src="assets/alerta.png" />
  <p>No se encontraron resultados para esta búsqueda</p>
</div>

<div
  class="loading-percentage-indicator"
  [ngStyle]="{
    width: (((clientsPercentageLoaded$ | async) || 0) * 100)?.toString() + '%'
  }"
></div>

<div class="table-spinner" *ngIf="isClientsDataBeingLoaded$ | async">
  <i nz-icon nzType="loading"></i>
  <p>Cargando más usuarios...</p>
</div>
