import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { IAppState, IAsyncAction } from 'src/app/lib/typings';
import {
  clientsTableClearQueryClicked,
  clientsTableInitialLoad,
  clientsTableQueryChange,
  clientsTableScroll,
  clientsTableSortArrowClicked,
} from '../../redux/clients.actions';
import {
  selectClients,
  selectClientsPercentageLoaded,
  selectFromClientsStore,
  selectIsDataClientsDataBeingLoaded,
  selectWasLastClientsPageReached,
} from '../../redux/clients.selectors';
import { IClientTable, IClientTableSorting } from '../../typings';
import { ClientsModelViewUtilService } from '../../services/clients-model-view-util.service';

@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
})
export class ClientsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: (keyof IClientTable)[] = [
    'firstName',
    'phone',
    'status',
    'dateOfLastModification',
    'entemis',
    'id',
  ];

  clients$!: Observable<IClientTable[]>;
  sorting$!: Observable<IClientTableSorting>;
  actionFetchNextPage$!: Observable<IAsyncAction>;
  wasLastPageReached$!: Observable<boolean>;
  isClientsDataBeingLoaded$!: Observable<boolean>;
  clientsPercentageLoaded$!: Observable<number>;
  scrollNextPageSub!: Subscription;
  scrollBackUpSub!: Subscription;

  @ViewChild(CdkScrollable)
  private readonly scrollableTable!: CdkScrollable;

  constructor(
    private readonly store: Store<IAppState>,
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly actions$: Actions,
    private readonly router: Router,
    public readonly clientsModelViewUtil: ClientsModelViewUtilService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(clientsTableInitialLoad());
    this.clients$ = this.store.select(selectClients);
    this.sorting$ = this.store.select(selectFromClientsStore('sorting'));
    this.actionFetchNextPage$ = this.store.select(
      selectFromClientsStore('actionFetchNextPage')
    );

    this.wasLastPageReached$ = this.store.select(
      selectWasLastClientsPageReached
    );

    this.isClientsDataBeingLoaded$ = this.store.select(
      selectIsDataClientsDataBeingLoaded
    );

    this.clientsPercentageLoaded$ = this.store.select(
      selectClientsPercentageLoaded
    );
  }

  ngAfterViewInit(): void {
    const scrollObs$ = this.scrollDispatcher
      .scrolled(300)
      .pipe(
        withLatestFrom(this.isClientsDataBeingLoaded$, this.wasLastPageReached$)
      );

    this.scrollNextPageSub = scrollObs$.subscribe(
      ([scroll, isLoading, wasLastPageReached]) => {
        if (scroll && !isLoading && !wasLastPageReached) {
          const target = scroll.getElementRef().nativeElement;
          const tableViewHeight = target.offsetHeight;
          const tableScrollHeight = target.scrollHeight; // length of all table
          const scrollLocation = target.scrollTop; // how far user scrolled
          // If the user has scrolled within 10px of the bottom, trigger event
          const buffer = 10;
          const limit = tableScrollHeight - tableViewHeight - buffer;
          if (scrollLocation >= limit && !isLoading) {
            this.store.dispatch(clientsTableScroll());
          }
        }
      }
    );

    this.scrollBackUpSub = this.actions$
      .pipe(
        ofType(
          clientsTableQueryChange,
          clientsTableClearQueryClicked,
          clientsTableSortArrowClicked
        )
      )
      .subscribe(() => {
        this.scrollableTable.scrollTo({ top: 0 });
      });
  }

  ngOnDestroy(): void {
    this.scrollNextPageSub.unsubscribe();
    this.scrollBackUpSub.unsubscribe();
  }

  handleSortChange(sorting: ISortEvent): void {
    this.store.dispatch(
      clientsTableSortArrowClicked({
        sorting: {
          field: sorting.active,
          sort: sorting.direction.toUpperCase() as 'ASC' | 'DESC',
        },
      })
    );
  }

  handleItemClick(id: string): void {
    this.router.navigateByUrl(`/clientes/tabla/${id}`);
  }
}

interface ISortEvent {
  active: 'dateOfLastModification';
  direction: 'asc' | 'desc';
}
