<div class="container">
  <div class="header" nz-row nzJustify="space-between" nzAlign="bottom">
    <div nz-col nzSpan="8">
      <h3>Usuario activo de Yappy</h3>
    </div>
    <div nz-col nzSpan="8" class="justify-end">
      <p>
        <a routerLink="/clientes/tabla">Filtro avanzado de usuarios</a>
      </p>
    </div>
  </div>
  <nz-divider nzType="horizontal"></nz-divider>

  <div
    nz-row
    class="spinner-row"
    nzJustify="center"
    *ngIf="(fetchDetailAction$ | async).isLoading"
  >
    <ng-template #indicatorTemplate
      ><i nz-icon nzType="loading" class="loading-spinner"></i
    ></ng-template>
    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
  </div>

  <div nz-row nzAlign="middle" *ngIf="!(fetchDetailAction$ | async).isLoading">
    <h2 class="section-title">Datos del usuario</h2>
  </div>

  <div
    class="info-row"
    nz-row
    nzAlign="middle"
    *ngIf="!(fetchDetailAction$ | async).isLoading"
  >
    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">nombre del cliente</p>
      <p class="info-row__text">
        {{ (clientDetail$ | async)?.firstName }}
        {{ (clientDetail$ | async)?.lastName }}
      </p>
    </div>

    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">Número de celular</p>
      <p class="info-row__text">{{ (clientDetail$ | async)?.phone }}</p>
    </div>

    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">ente mis</p>
      <p class="info-row__text">{{ (clientDetail$ | async)?.entemis }}</p>
    </div>

    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">estado</p>
      <p class="info-row__text">
        {{ clientModelViewUtil.getStatusWord((clientDetail$ | async)?.status) }}
      </p>
    </div>

    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">fecha de afiliación</p>
      <p class="info-row__text">
        {{ (clientDetail$ | async)?.dateOfAffiliation | date: "d / MMM / y" }}
      </p>
    </div>

    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">cuenta asociada</p>
      <p class="info-row__text">{{ (clientDetail$ | async)?.accountNumber }}</p>
    </div>

    <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
      <p class="info-row__label">banco</p>
      <p class="info-row__text">{{ (clientDetail$ | async)?.bank }}</p>
    </div>

    <div nz-col nzXs="24">
      <button
        class="btn-primary"
        (click)="isDrawerVisible = true"
        *ngIf="(clientDetail$ | async)?.status === 'active'"
      >
        Dar de baja al usuario
      </button>
    </div>

    <div
      nz-col
      nzXs="24"
      class="baja-container"
      *ngIf="(discharge$ | async) && !(fetchDischargeAction$ | async).isLoading"
    >
      <div nz-row>
        <h2 class="section-title">Motivo de baja</h2>
      </div>

      <div nz-row>
        <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
          <p class="info-row__label">dado de baja el</p>
          <p class="info-row__text">
            {{ (discharge$ | async)?.dischargeDate | date: "d / MMM / y" }}
          </p>
        </div>
        <div nz-col class="info-row__col" nzXs="24" nzMd="10" nzLg="6" nzXl="6">
          <p class="info-row__label">dado de baja por</p>
          <p class="info-row__text">{{ (discharge$ | async)?.dischargedBy }}</p>
        </div>
      </div>

      <div nz-row>
        <div nz-col class="info-row__col" nzXs="24">
          <p class="info-row__label">más detalle</p>
          <p class="info-row__text">
            <span class="info-row__baja-title">{{
              clientModelViewUtil.getDischargeMotiveWord(
                (discharge$ | async)?.dischargeReason
              )
            }}</span>
            <br />

            <span class="info-row__baja-text">
              {{ (discharge$ | async).dischargeDetails }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <nz-drawer
    [nzVisible]="isDrawerVisible"
    [nzWidth]="'70%'"
    (nzOnClose)="closeDrawerWithReset()"
    [nzMaskClosable]="false"
  >
    <ng-container *nzDrawerContent>
      <div class="drawer-container">
        <div nz-row>
          <h2>¿Por qué se le esta dando de baja?</h2>
        </div>

        <div nz-row>
          <p>
            Por favor detalle la razón para lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nunc laoreet a ipsum vitae viverra.
            Donec laoreet malesuada turpis sed ullamcorper.
          </p>
        </div>

        <div nz-row>
          <div nz-col nzSpan="24">
            <form
              nz-form
              nzLayout="horizontal"
              [formGroup]="form"
              (ngSubmit)="submitForm()"
            >
              <nz-form-item>
                <nz-form-control
                  [nzSpan]="24"
                  nzErrorTip="Por favor especifíque el motivo para dar de baja"
                >
                  <nz-radio-group formControlName="motive">
                    <label nz-radio nzValue="affiliationError"
                      >Error en afiliación</label
                    >
                    <label nz-radio nzValue="fraud">Fraude</label>
                    <label nz-radio nzValue="death">Fallecimiento</label>
                    <label nz-radio nzValue="other">Otro</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control
                  [nzSpan]="24"
                  nzErrorTip="Por favor introduzca detalles para dar de baja, este debe tener un mínimo de 5 caracteres"
                >
                  <label for="detalle">Más detalle</label>
                  <textarea
                    id="detalle"
                    rows="5"
                    nz-input
                    formControlName="detail"
                    [nzAutosize]="false"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>

              <div
                class="drawer-container__btn-row"
                nz-row
                nzJustify="end"
                [nzGutter]="[16, 16]"
              >
                <div nz-col>
                  <button
                    type="button"
                    class="drawer-container__btn-outline"
                    (click)="closeDrawerWithReset()"
                  >
                    Cancelar
                  </button>
                </div>
                <div nz-col>
                  <button
                    type="submit"
                    nz-button
                    [class]="
                      form.valid
                        ? 'drawer-container__btn-primary'
                        : 'drawer-container__btn-primary-disabled'
                    "
                    [disabled]="
                      !form.valid || (clientsDischargeAction$ | async).isLoading
                    "
                  >
                    {{
                      (clientsDischargeAction$ | async).isLoading
                        ? "Enviando..."
                        : "Dar de baja"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>

  <nz-modal
    [(nzVisible)]="isModalVisible"
    [nzClosable]="false"
    [nzFooter]="null"
    nzCentered
  >
    <ng-container class="modal-content" *nzModalContent>
      <div nz-row nzJustify="center">
        <i nz-icon nzType="check-circle" nzTheme="fill" class="modal-icon"></i>
      </div>

      <div nz-row nzJustify="center">
        <p class="modal-content__message">
          El usuario se ha dado de baja con éxito
        </p>
      </div>
      <div nz-row nzJustify="center">
        <button class="modal-content__btn" (click)="isModalVisible = false">
          Entendido
        </button>
      </div>
    </ng-container>
  </nz-modal>
</div>
