import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, OperatorFunction } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { IAppState } from 'src/app/lib/typings';
import { ClientsHttpService } from '../services/clients-http.service';
import {
  EClientStatus,
  EDischargeMotive,
  IClientTablePagination,
  IClientTableQuery,
  IClientTableSorting,
} from '../typings';
import {
  clientsTableClearQueryClicked,
  clientsReloadFailure,
  clientsNextPageSuccess,
  clientsReloadSuccess,
  clientsTableQueryChange,
  clientsTableScroll,
  clientsNextPageFailure,
  clientsCompletePhoneInput,
  clientsFetchDetailSuccess,
  clientsFetchDetailFailure,
  clientsTableInitialLoad,
  clientDischargeFormSubmit,
  clientDischargeSuccess,
  clientDischargeFailure,
  clientDetailPageNavigatedTo,
  clientFetchDischargeSuccess,
  clientFetchDischargeFailure,
  clientsTableSortArrowClicked,
} from './clients.actions';
import { selectFromClientsStore } from './clients.selectors';
import { client } from '../lib/mocks/mock-store-data';

@Injectable()
export class ClientsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly clientsHttp: ClientsHttpService,
    private readonly store: Store<IAppState>,
    private readonly router: Router
  ) {}

  private readonly reasonsToReload = ofType(
    clientsTableQueryChange,
    clientsTableClearQueryClicked,
    clientsTableInitialLoad,
    clientsTableSortArrowClicked
  );

  private readonly withLatestQueryData = withLatestFrom(
    this.store.select(selectFromClientsStore('query')),
    this.store.select(selectFromClientsStore('pagination')),
    this.store.select(selectFromClientsStore('sorting'))
  ) as OperatorFunction<
    unknown,
    [unknown, IClientTableQuery, IClientTablePagination, IClientTableSorting]
  >; // el higher order selector es muy útil por que evitamos la necesidad de crear selectors redundante
  // pero aún no logro que "conozca" los tipos que esta seleccionando (aunque sí conoce las llaves del store)

  loadClientsData$ = createEffect(() =>
    this.actions$.pipe(
      this.reasonsToReload,
      this.withLatestQueryData,
      debounceTime(500),
      switchMap(([_, query, __, sorting]) =>
        this.clientsHttp.reload({ query, sorting }).pipe(
          map((response) => clientsReloadSuccess({ response })),
          catchError(({ message }: HttpErrorResponse) =>
            of(clientsReloadFailure({ error: message }))
          )
        )
      )
    )
  );

  fetchNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsTableScroll),
      this.withLatestQueryData,
      debounceTime(500),
      switchMap(([_, query, pagination, sorting]) =>
        this.clientsHttp.fetchNextPage({ query, pagination, sorting }).pipe(
          map((response) => clientsNextPageSuccess({ response })),
          catchError(({ message }: HttpErrorResponse) =>
            of(clientsNextPageFailure({ error: message }))
          )
        )
      )
    )
  );

  fetchClientDetailByPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientsCompletePhoneInput),
      debounceTime(100),
      switchMap(({ phone }) =>
        this.clientsHttp.fetchOneByPhone(phone).pipe(
          map((client) => clientsFetchDetailSuccess({ client })),
          tap(({ client }) => {
            this.router.navigateByUrl(`/clientes/tabla/${client.id}`);
          }),
          catchError(({ message }: HttpErrorResponse) =>
            of(clientsFetchDetailFailure({ error: message })).pipe(delay(500))
          )
        )
      )
    )
  );

  fetchClientDetailById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDetailPageNavigatedTo),
      debounceTime(100),
      switchMap(({ clientId }) =>
        this.clientsHttp.fetchOneClientDetailById(clientId).pipe(
          map((client) => clientsFetchDetailSuccess({ client })),
          catchError(({ message }: HttpErrorResponse) => {
            this.router.navigateByUrl('/clientes/tabla');
            return of(clientsFetchDetailFailure({ error: message }));
          })
        )
      )
    )
  );

  dischargeClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDischargeFormSubmit),
      concatMap(({ dto }) =>
        this.clientsHttp.dischargeClient(dto).pipe(
          map((dto) => {
            const { data } = dto;
            const {
              reportId,
              dischargeDate,
              dischargedBy,
              dischargeReason,
              dischargeDetails,
              client,
            } = data;

            const {
              clientId,
              firstName,
              lastName,
              phone,
              status,
              updateDate,
            } = client;
            const discharge = {
              id: reportId,
              clientId: clientId,
              dischargeDate: new Date(dischargeDate),
              dischargedBy: dischargedBy,
              dischargeReason: dischargeReason,
              dischargeDetails: dischargeDetails,
            };

            const resClient = {
              id: clientId,
              firstName: firstName,
              lastName: lastName,
              phone: phone,
              status: status,
              dateOfLastModification: new Date(updateDate),
              entemis: '#1234570',
            };

            return clientDischargeSuccess({
              discharge,
              client: resClient,
            });
          }),
          catchError(({ message }: HttpErrorResponse) =>
            of(clientDischargeFailure({ error: message }))
          )
        )
      )
    )
  );

  fetchDischarge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clientDetailPageNavigatedTo),
      switchMap(({ clientId }) =>
        this.clientsHttp.fetchOneClientDischargeByClientId(clientId).pipe(
          map((discharge) => clientFetchDischargeSuccess({ discharge })),
          catchError(({ message }: HttpErrorResponse) =>
            of(clientFetchDischargeFailure({ error: message }))
          )
        )
      )
    )
  );
}
