import { createAction, props } from '@ngrx/store';
import {
  IClientsTableResponse,
  IClientTableQuery,
  IClientDetail,
  IClientDischargeDto,
  IClientDischarge,
  IClientTable,
  IClientTableSorting,
} from '../typings';

export const clientsTableInitialLoad = createAction(
  '[Clients Table Page] Initial Data Load'
);

export const clientsTableQueryChange = createAction(
  '[Clients Table] Query Changed',
  props<{ query: Partial<IClientTableQuery> }>()
);

export const clientsTableClearQueryClicked = createAction(
  '[Clients Table] Clear Query Clicked'
);

export const clientsReloadSuccess = createAction(
  '[Clients Effects] Reload Clients Success',
  props<{ response: IClientsTableResponse }>()
);

export const clientsReloadFailure = createAction(
  '[Clients Effects] Reload Clients Failure',
  props<{ error: string }>()
);

export const clientsTableScroll = createAction(
  '[Clients Table] Clients Table Scroll'
);

export const clientsNextPageSuccess = createAction(
  '[Clients Effects] Clients Next Page Success',
  props<{ response: IClientsTableResponse }>()
);

export const clientsNextPageFailure = createAction(
  '[Clients Effects] Clients Next Page Failure',
  props<{ error: string }>()
);

export const clientsCompletePhoneInput = createAction(
  '[Clients Table Page Header] Complete Phone Input',
  props<{ phone: string }>()
);

export const clientsFetchDetailSuccess = createAction(
  '[Clients Effects] Fetch Client Detail Success',
  props<{ client: IClientDetail }>()
);

export const clientsFetchDetailFailure = createAction(
  '[Clients Effects] Fetch Client Detail Failure',
  props<{ error: string }>()
);

export const clientDischargeFormSubmit = createAction(
  '[Client Detail Page] Discharge Form Submit',
  props<{ dto: IClientDischargeDto }>()
);

export const clientDischargeSuccess = createAction(
  '[Clients Effects] Discharge Success',
  props<{ discharge: IClientDischarge; client: IClientTable }>()
);

export const clientDischargeFailure = createAction(
  '[Clients Effects] Discharge Failure',
  props<{ error: string }>()
);

export const clientDetailPageNavigatedTo = createAction(
  '[Client Detail Page] Navigated To',
  props<{ clientId: string }>()
);

export const clientFetchDischargeSuccess = createAction(
  '[Clients Effects] Fetch Discharge Success',
  props<{ discharge: IClientDischarge }>()
);

export const clientFetchDischargeFailure = createAction(
  '[Clients Effects] Fetch Discharge Failure',
  props<{ error: string }>()
);

export const clientsTableSortArrowClicked = createAction(
  '[Clients Table] Sort Arrow Clicked',
  props<{ sorting: IClientTableSorting }>()
);
