import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clients-header',
  templateUrl: './clients-header.component.html',
  styleUrls: ['./clients-header.component.scss'],
})
export class ClientsHeaderComponent implements OnInit {
  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  handleClick(e: Event): void {
    e.preventDefault();
    this.router.navigateByUrl('/clientes/búsqueda');
  }
}
