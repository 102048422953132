import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-single-search-page',
  templateUrl: './clients-single-search-page.component.html',
  styleUrls: ['./clients-single-search-page.component.scss'],
})
export class ClientsSingleSearchPageComponent implements OnInit, AfterViewInit {
  isLoading = false;
  clientNotFound = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.clientNotFound = false;
  }
}
