<div class="nav" nz-row nzJustify="space-between" nzAlign="middle">
  <div nz-col nzXs="8" nzMd="10">
    <div (click)="handleClick($event)" class="nav__logo clickable">
      <img src="assets/svg/bg-logo.svg" alt="bg-logo">
      <h1 class="nav__logo__title">Yappy Check</h1>
    </div>
  </div>
  <div nz-col nzXs="14" nzMd="6">
    <app-clients-phone-search></app-clients-phone-search>
  </div>
</div>