import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-root-layout',
  templateUrl: './clients-root-layout.component.html',
  styleUrls: ['./clients-root-layout.component.scss']
})
export class ClientsRootLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
