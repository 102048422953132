import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import {
  BASE_URL,
  CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP,
  TEST_CLIENTS_DATA,
  TEST_CLIENT_DETAIL_EXTENSION,
  TEST_CLIENT_DISCHARGES,
} from '../constants';
import { INITIAL_CLIENTS_REDUCER_STATE } from '../redux/clients.reducer';
import {
  IClientsTableResponse,
  IClientTablePagination,
  IClientTableQuery,
  IClientDetail,
  IClientDischarge,
  IClientDischargeDto,
  EClientStatus,
  IClientTable,
  IClientTableSorting,
  EDischargeMotive,
} from '../typings';

@Injectable({
  providedIn: 'root',
})
export class ClientsHttpService {
  constructor(private readonly http: HttpClient) {}

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  reload({
    query,
    sorting,
  }: {
    query: IClientTableQuery;
    sorting: IClientTableSorting;
  }): Observable<IClientsTableResponse> {
    /*return of({
      total: TEST_CLIENTS_DATA.filter(
        ({ status }) => query.status === status || !query.status
      ).length,
      clients: TEST_CLIENTS_DATA.filter(
        ({ status }) => query.status === status || !query.status
      ).slice(0, INITIAL_CLIENTS_REDUCER_STATE.pagination.pageSize),
    }).pipe(delay(300));*/
    return this.http.post<IClientsTableResponse>(
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.reload.url,
      {
        ...query,
        ...sorting,
        page: 0,
        pageSize: 7,
      },
      { headers: CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.reload.headers }
    );
  }

  fetchNextPage({
    query,
    sorting,
    pagination,
  }: {
    query: IClientTableQuery;
    sorting: IClientTableSorting;
    pagination: IClientTablePagination;
  }): Observable<IClientsTableResponse> {
    /*return of({
      total: TEST_CLIENTS_DATA.filter(
        ({ status }) => query.status === status || !query.status
      ).length,
      clients: TEST_CLIENTS_DATA.filter(
        ({ status }) => query.status === status || !query.status
      ).slice(
        (pagination.page ? pagination.page - 1 : 0) * pagination.pageSize,
        (pagination.page ? pagination.page - 1 : 0) * pagination.pageSize +
          pagination.pageSize
      ),
    }).pipe(delay(300));*/

    return this.http[
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchNextPage.method
    ]<IClientsTableResponse>(
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchNextPage.url,
      {
        ...query,
        ...sorting,
        ...pagination,
      },
      { headers: CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.reload.headers }
    );
  }

  fetchOneByPhone(phone: string): Observable<IClientDetail> {
    const unHiphenedPhone = phone.replace('-', '');

    /*const foundClient = TEST_CLIENTS_DATA.find(
      (client) => client.phone === unHiphenedPhone
    );

    if (foundClient) {
      return of({
        ...foundClient,
        ...TEST_CLIENT_DETAIL_EXTENSION,
      }).pipe(delay(300));
    }

    return throwError({ message: '404, client not found by phone!' });*/

    return this.http[
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneByPhone.method
    ]<IClientDetail>(
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneByPhone.url,
      {
        phone,
      },
      {
        headers: CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneByPhone.headers,
      }
    ).pipe(
      map((client: any) => ({ ...client, ...client.clientDetails })),
      catchError((e) =>
        throwError({ message: '404, client not found by phone!' })
      )
    );
  }

  fetchOneClientDetailById(id: string): Observable<IClientDetail> {
    /*const foundClient = TEST_CLIENTS_DATA.find((client) => client.id === id);
    if (foundClient) {
      return of({
        ...foundClient,
        ...TEST_CLIENT_DETAIL_EXTENSION,
      }).pipe(delay(300));
    }

    return throwError({ message: '404, client not found by id!' });*/
    return this.http[
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneClientDetailById.method
    ]<IClientDetail>(
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneClientDetailById.url,
      { id },
      {
        headers:
          CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneClientDetailById
            .headers,
      }
    ).pipe(
      map((client: any) => ({
        ...client,
        ...client.clientDetails,
      }))
    );
  }

  fetchOneClientDischargeByClientId(id: string): Observable<IClientDischarge> {
    /*const discharge = TEST_CLIENT_DISCHARGES.filter(
      (d) => d.clientId === clientId
    )[0];

    if (discharge) {
      return of(discharge).pipe(delay(300));
    }

    return throwError({ message: '404, Discharge not found by clientId!' });*/
    return this.http[
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneClientDischargeByClientId
        .method
    ]<IClientDetail>(
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.fetchOneClientDischargeByClientId
        .url,
      { id },
      {
        headers:
          CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP
            .fetchOneClientDischargeByClientId.headers,
      }
    );
  }

  fetchDischargeMotives(): Observable<EDischargeMotive[]> {
    return of(Object.values(EDischargeMotive));

    /*   return this.http.post<EDischargeMotive[]>(
      '/api/ce/yp01/yappy/GetDischargeMotives',
      {}
    ); */
  }

  dischargeClient(
    dto: IClientDischargeDto
  ): Observable<{ data: any; result: boolean; error: any }> {
    const newDischarge: IClientDischarge = {
      ...dto,
      id: `${TEST_CLIENT_DISCHARGES.length + 1}`,
      dischargeDate: new Date(),
      dischargedBy: 'Fulano de Tal',
    };

    // const clientIndexToDischarge = TEST_CLIENTS_DATA.findIndex(
    //   ({ id }) => id === dto.clientId
    // );

    // TEST_CLIENTS_DATA[clientIndexToDischarge] = {
    //   ...TEST_CLIENTS_DATA[clientIndexToDischarge],
    //   status: EClientStatus.Discharged,
    // };

    // TEST_CLIENT_DISCHARGES.push(newDischarge);
    // return of({
    //   discharge: newDischarge,
    //   client: TEST_CLIENTS_DATA[clientIndexToDischarge],
    // }).pipe(delay(500));

    return this.http[
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.dischargeClient.method
    ]<any>(
      CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.dischargeClient.url,
      {
        ...newDischarge,
      },
      {
        headers: CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP.dischargeClient.headers,
      }
    );

    /*   return this.http.post<IClientDischarge>('/api/ce/yp02/yappy/Discharge', dto); */
  }
}

/*
 * tabla -> http://localhost:8443/api/ce/yp02/yappy/ClientList
 * detalle -> http://localhost:8443/api/ce/yp02/yappy/ClientInfo
 * baja -> http://localhost:8443/api/ce/yp02/yappy/DischargeInfo
 * get motives -> /api/ce/yp01/yappy/GetDischargeMotives
 */
