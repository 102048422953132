import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-phone-search',
  templateUrl: './clients-phone-search.component.html',
  styleUrls: ['./clients-phone-search.component.scss'],
})
export class ClientsPhoneSearchComponent implements OnInit {
  isLoading = false

  constructor() {}

  ngOnInit(): void {
  
  }
}
