import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-table-page',
  templateUrl: './clients-table-page.component.html',
  styleUrls: ['./clients-table-page.component.scss'],
})
export class ClientsTablePageComponent implements OnInit {
  ngOnInit(): void {}
}
