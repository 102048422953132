import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState, IAsyncAction } from 'src/app/lib/typings';
import {
  clientDetailPageNavigatedTo,
  clientDischargeFormSubmit,
  clientDischargeSuccess,
} from '../../redux/clients.actions';
import { selectFromClientsStore } from '../../redux/clients.selectors';
import { ClientsModelViewUtilService } from '../../services/clients-model-view-util.service';
import { IClientDetail, IClientDischarge } from '../../typings';

function validateDetail(group: FormGroup) {
  const motive = group.get('motive').value;
  const detail = group.get('detail').value;

  if (motive === 'other' && detail.trim().length < 5) {
    return { required: true };
  }

  return null;
}

@Component({
  selector: 'app-clients-detail-page',
  templateUrl: './clients-detail-page.component.html',
  styleUrls: ['./clients-detail-page.component.scss'],
})
export class ClientsDetailPageComponent implements OnInit, OnDestroy {
  isModalVisible = false;
  isDrawerVisible = false;
  discharge$: Observable<IClientDischarge>;
  clientDetail$: Observable<IClientDetail>;
  form: FormGroup;
  clientsDischargeAction$!: Observable<IAsyncAction>;
  fetchDischargeAction$!: Observable<IAsyncAction>;
  fetchDetailAction$!: Observable<IAsyncAction>;
  clientDischargeSuccessSub!: Subscription;

  constructor(
    private readonly store: Store<IAppState>,
    private readonly formBuilder: FormBuilder,
    private readonly actions: Actions,
    private readonly route: ActivatedRoute,
    public readonly clientModelViewUtil: ClientsModelViewUtilService
  ) {
    this.form = this.formBuilder.group(
      {
        motive: ['', [Validators.required]],
        detail: ['', null],
      },
      { validator: validateDetail }
    );
  }

  ngOnInit(): void {
    this.store.dispatch(
      clientDetailPageNavigatedTo({
        clientId: this.route.snapshot.paramMap.get('id'),
      })
    );

    this.discharge$ = this.store.select(
      selectFromClientsStore('dischargeDetail')
    );

    this.fetchDetailAction$ = this.store.select(
      selectFromClientsStore('actionFetchDetail')
    );

    this.clientDetail$ = this.store.select(selectFromClientsStore('detail'));
    this.clientsDischargeAction$ = this.store.select(
      selectFromClientsStore('actionDischargeClient')
    );

    this.fetchDischargeAction$ = this.store.select(
      selectFromClientsStore('actionFetchDischarge')
    );

    this.clientDischargeSuccessSub = this.actions
      .pipe(ofType(clientDischargeSuccess))
      .subscribe(() => {
        this.isModalVisible = true;
        this.isDrawerVisible = false;
        this.form.reset({
          motive: '',
          detail: '',
        });
      });
  }

  ngOnDestroy(): void {
    this.clientDischargeSuccessSub.unsubscribe();
  }

  submitForm(): void {
    // tslint:disable-next-line: forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    if (this.form.valid) {
      this.store.dispatch(
        clientDischargeFormSubmit({
          dto: {
            dischargeReason: this.form.value.motive,
            clientId: this.route.snapshot.paramMap.get('id'),
            dischargeDetails: this.form.value.detail.trim(),
          },
        })
      );
    }
  }

  closeDrawerWithReset(): void {
    this.isDrawerVisible = false;
    this.form.reset({
      motive: '',
      detail: '',
    });
  }
}
