import { routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { IAppState } from '../typings';

const localStorageSyncReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> =>
  localStorageSync({
    keys: ['clients'],
    rehydrate: true,
  })(reducer);

export const rootMetaReducers: MetaReducer<any>[] = [localStorageSyncReducer];
export const ROOT_REDUCERS: ActionReducerMap<Pick<IAppState, 'router'>> = {
  router: routerReducer,
};
