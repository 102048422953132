import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from 'src/app/lib/typings';
import { selectFromClientsStore } from '../../redux/clients.selectors';

@Component({
  selector: 'app-clients-table-header',
  templateUrl: './clients-table-header.component.html',
  styleUrls: ['./clients-table-header.component.scss'],
})
export class ClientsTableHeaderComponent implements OnInit {
  total$!: Observable<number>;

  constructor(private readonly store: Store<IAppState>) {}

  ngOnInit(): void {
    this.total$ = this.store.select(selectFromClientsStore('total'));
  }
}
