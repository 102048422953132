import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientsTablePageComponent } from './pages/clients-table-page/clients-table-page.component';
import { ClientsDetailPageComponent } from './pages/clients-detail-page/clients-detail-page.component';
import { ClientsRootLayoutComponent } from './layout/clients-root-layout/clients-root-layout.component';
import { ClientsSingleSearchPageComponent } from './pages/clients-single-search-page/clients-single-search-page.component';

const ROUTES: Routes = [
  {
    path: 'búsqueda',
    component: ClientsSingleSearchPageComponent,
  },
  {
    path: 'tabla',
    component: ClientsRootLayoutComponent,
    children: [
      { path: '', component: ClientsTablePageComponent },
      { path: ':id', component: ClientsDetailPageComponent },
      { path: '**', redirectTo: '' },
    ],
  },
  {
    path: '**',
    redirectTo: 'búsqueda',
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class ClientsRoutingModule {}
