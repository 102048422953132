<div nz-row nzJustify="space-around" [nzGutter]="[4, 16]" class="filter-row">
  <div nz-col nzXs="24" nzMd="8" class="query-element">
    <p class="query-element__title">Última fecha de modificación</p>
    <nz-range-picker
      [(ngModel)]="dateRangeTuple"
      [nzRanges]="dateRanges"
      [nzPlaceHolder]="['Desde', 'Hasta']"
      nzFormat="dd/MM/yyyy"
      (ngModelChange)="handleDatesRangeChange($event)"
      style="width: 100%"
    ></nz-range-picker>
  </div>
  <div nz-col nzXs="24" nzMd="8" class="query-element">
    <p class="query-element__title">Estados</p>
    <nz-select
      [ngModel]="(query$ | async)?.status"
      [nzAllowClear]="true"
      nzPlaceHolder="Estado"
      (ngModelChange)="handleStatusChange($event)"
      style="width: 100%"
    >
      <nz-option nzValue="active" nzLabel="Activos"></nz-option>
      <nz-option nzValue="discharged" nzLabel="Dados de baja"></nz-option>
      <nz-option [nzValue]="undefined" nzLabel="Todos los estados"></nz-option>
    </nz-select>
  </div>
  <div nz-col nzXs="24" nzMd="8" class="query-element query-element--button">
    <button
      nz-button
      nzType="primary"
      style="background-color: white; color: #1677d8"
      [disabled]="selectIsDefaultQuerySelected$ | async"
      (click)="handleClearFilter($event)"
      [ngStyle]="{
        color: (selectIsDefaultQuerySelected$ | async) ? '#9FA6B7' : '#1677d8',
        'background-color':
          (selectIsDefaultQuerySelected$ | async) ? '#EBEEF4' : 'white'
      }"
      style="width: 100%"
    >
      <i nz-icon nzType="delete" nzTheme="outline"></i>
      Limpiar filtro
    </button>
  </div>
</div>
