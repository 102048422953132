<div class="container">
  <app-clients-table-header></app-clients-table-header>
  <nz-divider nzType="horizontal"></nz-divider>
  <app-clients-table-query></app-clients-table-query>
  <nz-divider nzType="horizontal"></nz-divider>
  <div nz-row class="table-container">
    <div nzCol nzSpan="24" class="table-column">
      <app-clients-table></app-clients-table>
    </div>
  </div>
</div>
