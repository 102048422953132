import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

@NgModule({
  imports: [
    NzButtonModule,
    NzDrawerModule,
    NzGridModule,
    NzModalModule,
    NzInputModule,
    NzRadioModule,
    NzSelectModule,
    NzDividerModule,
    NzIconModule,
    NzTableModule,
    NzFormModule,
    NzDatePickerModule,
    NzSpinModule,
  ],
  exports: [
    NzButtonModule,
    NzDrawerModule,
    NzGridModule,
    NzModalModule,
    NzInputModule,
    NzRadioModule,
    NzSelectModule,
    NzDividerModule,
    NzIconModule,
    NzTableModule,
    NzFormModule,
    NzDatePickerModule,
    NzSpinModule,
  ],
})
export class NGZorroModule {}
