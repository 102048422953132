import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/lib/typings';
import { higherOrderSelector } from 'src/app/lib/redux/lib.redux-utils';
import {
  DEFAULT_CLIENTS_TABLE_QUERY,
  clientsEntitySelectors,
} from './clients.reducer';
import { IClientTable, IClientsReducerState } from '../typings';

export const selectClientsState = createFeatureSelector<IClientsReducerState>(
  'clients'
);

export const selectFromClientsStore = higherOrderSelector(selectClientsState);
export const selectClients = createSelector<
  IAppState,
  IClientsReducerState,
  IClientTable[]
>(selectClientsState, clientsEntitySelectors.selectAll);

export const selectWasLastClientsPageReached = createSelector<
  IAppState,
  IClientsReducerState,
  boolean
>(
  selectClientsState,
  ({ total, pagination: { pageSize, page } }) =>
    page + 1 >= Math.ceil(total / pageSize)
);

export const selectIsDefaultQuerySelected = createSelector<
  IAppState,
  IClientsReducerState,
  boolean
>(
  selectClientsState,
  ({ query }) =>
    JSON.stringify(query) === JSON.stringify(DEFAULT_CLIENTS_TABLE_QUERY)
);

export const selectIsDataClientsDataBeingLoaded = createSelector<
  IAppState,
  IClientsReducerState,
  boolean
>(
  selectClientsState,
  ({ actionFetchNextPage, actionReload }) =>
    actionFetchNextPage.isLoading || actionReload.isLoading
);

export const selectClientsPercentageLoaded = createSelector<
  IAppState,
  IClientsReducerState,
  number
>(selectClientsState, ({ total, ids }) => ids.length / total);
