<div class="container">
  <div class="header">
    <img src="assets/svg/bg-logo.svg" alt="bg-logo">
    <h1>Yappy Check</h1>
  </div>

  <div class="content">
    <p class="content__label content__spacing">Busca un usuario en Yappy</p>

    <input class="content__input" nz-input placeholder="Número de celular..." yappyPhoneSearch
      (yappyOnLoadingChange)="isLoading = $event" (yappyOnError)="clientNotFound = $event" />
    <div class="content__error-section" *ngIf="clientNotFound">
      <p class="content__error-section__error">
        <span class="icon baseline">
          <img src="assets/svg/error-circle.svg" alt="error-circle">
        </span> Este usuario no existe
      </p>
    </div>

    <ng-template #indicatorTemplate><i nz-icon nzType="loading" *ngIf="isLoading"></i></ng-template>
    <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
    <a class="content__filter" routerLink="/clientes/tabla">Filtro avanzado de usuarios</a>
  </div>
</div>