import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IAsyncAction } from 'src/app/lib/typings';
import {
  IClientTable,
  IClientsStateExtension,
  IClientTableQuery,
  EClientStatus,
} from '../typings';
import {
  clientsTableClearQueryClicked,
  clientsNextPageFailure,
  clientsNextPageSuccess,
  clientsReloadSuccess,
  clientsTableQueryChange,
  clientsTableScroll,
  clientsReloadFailure,
  clientsCompletePhoneInput,
  clientsFetchDetailSuccess,
  clientsFetchDetailFailure,
  clientDischargeFormSubmit,
  clientDischargeSuccess,
  clientDischargeFailure,
  clientsTableInitialLoad,
  clientDetailPageNavigatedTo,
  clientFetchDischargeSuccess,
  clientFetchDischargeFailure,
  clientsTableSortArrowClicked,
} from './clients.actions';

export const clientsAdapter = createEntityAdapter<IClientTable>();
export const clientsEntitySelectors = clientsAdapter.getSelectors();
export const DEFAULT_CLIENTS_TABLE_QUERY: IClientTableQuery = {
  status: null,
};

const ASYNC_ACTION_DEFAULT: IAsyncAction = {
  isLoading: false,
  error: null,
};

const INITIAL_CLIENTS_ENTITY_STATE_EXTENSION: IClientsStateExtension = {
  query: DEFAULT_CLIENTS_TABLE_QUERY,
  pagination: { page: 0, pageSize: 7 },
  sorting: {
    field: 'dateOfLastModification',
    sort: 'DESC',
  },
  total: 0,
  actionFetchNextPage: ASYNC_ACTION_DEFAULT,
  actionReload: ASYNC_ACTION_DEFAULT,
  actionFetchDetail: ASYNC_ACTION_DEFAULT,
  actionDischargeClient: ASYNC_ACTION_DEFAULT,
  actionFetchDischarge: ASYNC_ACTION_DEFAULT,
};

export const INITIAL_CLIENTS_REDUCER_STATE = clientsAdapter.getInitialState(
  INITIAL_CLIENTS_ENTITY_STATE_EXTENSION
);

export const clientsReducer = createReducer(
  INITIAL_CLIENTS_REDUCER_STATE,
  on(clientsTableQueryChange, (state, payload) => ({
    ...state,
    query: {
      ...state.query,
      ...payload.query,
    },
    pagination: {
      ...state.pagination,
      page: 0,
    },
    actionReload: {
      isLoading: true,
      error: null,
    },
  })),
  on(clientsTableClearQueryClicked, (state) => ({
    ...state,
    query: DEFAULT_CLIENTS_TABLE_QUERY,
    pagination: {
      ...state.pagination,
      page: 0,
    },
    actionReload: {
      isLoading: true,
      error: null,
    },
  })),
  on(clientsReloadSuccess, (state, payload) => ({
    ...state,
    ...clientsAdapter.setAll(payload.response.clients, state),
    total: payload.response.total,
    actionReload: {
      isLoading: false,
      error: null,
    },
  })),
  on(clientsReloadFailure, (state, { error }) => ({
    ...state,
    actionReload: {
      isLoading: false,
      error,
    },
  })),
  on(clientsTableScroll, (state) => ({
    ...state,
    actionFetchNextPage: {
      isLoading: true,
      error: null,
    },
    pagination: {
      ...state.pagination,
      page:
        state.pagination.page + 1 >
        Math.ceil(state.total / state.pagination.pageSize)
          ? Math.ceil(state.total / state.pagination.pageSize)
          : state.pagination.page + 1, // * asegurar que no se pase de la última página posible
    },
  })),
  on(clientsNextPageSuccess, (state, payload) => ({
    ...state,
    ...clientsAdapter.addMany(payload.response.clients, state),
    actionFetchNextPage: {
      isLoading: false,
      error: null,
    },
  })),
  on(clientsNextPageFailure, (state, { error }) => ({
    ...state,
    actionFetchNextPage: {
      isLoading: false,
      error,
    },
    pagination: {
      ...state.pagination,
      page: state.pagination.page - 1,
    },
  })),
  on(clientsCompletePhoneInput, (state) => ({
    ...state,
    actionFetchDetail: {
      isLoading: true,
      error: null,
    },
  })),
  on(clientsFetchDetailSuccess, (state, { client }) => ({
    ...state,
    actionFetchDetail: {
      isLoading: false,
      error: null,
    },
    detail: client,
  })),
  on(clientsFetchDetailFailure, (state, { error }) => ({
    ...state,
    actionFetchDetail: {
      isLoading: false,
      error,
    },
  })),
  on(clientDischargeFormSubmit, (state) => ({
    ...state,
    actionDischargeClient: {
      isLoading: true,
      error: null,
    },
  })),
  on(clientDischargeSuccess, (state, { discharge, client }) => ({
    ...state,
    ...clientsAdapter.updateOne(
      { id: client.id, changes: { status: EClientStatus.Discharged } },
      state
    ),
    actionDischargeClient: {
      isLoading: false,
      error: null,
    },
    detail: {
      ...state.detail,
      status: EClientStatus.Discharged,
    },
    dischargeDetail: discharge,
  })),
  on(clientDischargeFailure, (state, { error }) => ({
    ...state,
    actionDischargeClient: {
      isLoading: false,
      error,
    },
  })),
  on(clientsTableInitialLoad, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      page: 0,
    },
  })),
  on(clientDetailPageNavigatedTo, (state) => ({
    ...state,
    actionFetchDischarge: {
      isLoading: true,
      error: null,
    },
    actionFetchDetail: {
      isLoading: true,
      error: null,
    },
  })),
  on(clientFetchDischargeSuccess, (state, { discharge }) => ({
    ...state,
    actionFetchDischarge: {
      isLoading: false,
      error: null,
    },
    dischargeDetail: discharge,
  })),
  on(clientFetchDischargeFailure, (state, { error }) => ({
    ...state,
    actionFetchDischarge: {
      isLoading: false,
      error,
    },
    dischargeDetail: undefined,
  })),
  on(clientsTableSortArrowClicked, (state, { sorting }) => ({
    ...state,
    sorting,
    actionReload: {
      isLoading: true,
      error: null,
    },
    pagination: {
      ...state.pagination,
      page: 0,
    },
  }))
);
