import {
  EClientStatus,
  EDischargeMotive,
  IClientDetail,
  IClientDischarge,
  IClientTable,
} from '../typings';

const utilArray = new Array(1000).fill(null);

const REAL_PEOPLE: { [key: number]: IClientTable } = {
  199: {
    id: '200',
    firstName: 'Norberto',
    lastName: 'Cáceres',
    phone: '63494074',
    status: EClientStatus.Active,
    dateOfLastModification: new Date(),
    entemis: '#7234863',
  },
  58: {
    id: '59',
    firstName: 'Daniel',
    lastName: 'Justavino',
    phone: '65474230',
    status: EClientStatus.Active,
    dateOfLastModification: new Date(),
    entemis: '#9632361',
  },
};

export const TEST_CLIENTS_DATA: IClientTable[] = utilArray.map(
  (_, i) =>
    REAL_PEOPLE[i] || {
      id: (i + 1).toString(),
      firstName: `Test`,
      lastName: `${i + 1}`,
      phone: '12345678',
      status: i % 10 === 0 ? EClientStatus.Discharged : EClientStatus.Active,
      dateOfLastModification: new Date(),
      entemis: '#1234567',
    }
);

export const TEST_CLIENT_DETAIL_EXTENSION: Omit<
  IClientDetail,
  keyof IClientTable
> = {
  bank: 'Banco General',
  accountNumber: '58878424915483',
  dateOfAffiliation: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 12 * 2.5), // let's say they affiliated 2.5 years ago
};

export const CLIENT_STATUS_TO_WORD_MAP: { [key in EClientStatus]: string } = {
  active: 'Activo',
  discharged: 'Dado de baja',
};

export const TEST_CLIENT_DISCHARGES: IClientDischarge[] = TEST_CLIENTS_DATA.filter(
  ({ status }) => status === EClientStatus.Discharged
).map((client, i) => ({
  id: `${i + 1}`,
  clientId: client.id,
  dischargeDate: new Date(),
  dischargedBy: 'Norberto Cáceres',
  dischargeReason:
    i % 2 === 0 ? EDischargeMotive.Death : EDischargeMotive.AffiliationError,
  dischargeDetails: 'Programatically generated discharge',
}));

export const REGEX_COMPLETE_PHONE_NUMBER = /^([0-9]{4})-([0-9]{4})$/;
export const REGEX_ACCEPTABLE_INPUT_PHONE_NUMBER = /(^([0-9]{4})-?([0-9]{0,4})$)|(^[0-9]{1,4}$)/;
export const REGEX_TO_ADD_HYPHEN_TO = /^([0-9]{5,8})$/;
export const REGEX_ACCEPTABLE_ALPHANUMERIC_DATA = /[^\W_]/;
export const DISCHARGE_MOTIVE_SPANISH_WORD_MAP: {
  [key in EDischargeMotive]: string;
} = {
  affiliationError: 'Error en afiliación',
  death: 'Fallecimiento',
  fraud: 'Fraude',
  other: 'Otro',
};

export const BASE_URL =
  'http://bg-poc-infra-1298228730.us-east-2.elb.amazonaws.com';

const HEADERS = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers':
    'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  Allow: 'GET, POST, OPTIONS',
};

export const CLIENTS_HTTP_SERVICE_METHOD_TO_API_MAP = {
  reload: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/ClientList`,
    headers: HEADERS,
  },
  fetchOneClientDetailById: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/ClientInfo`,
    headers: HEADERS,
    argsToDataSentMap: {
      0: {
        location: 'body',
        key: 'id',
      },
    },
  },
  fetchNextPage: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/ClientList`,
    headers: HEADERS,
  },
  fetchOneByPhone: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/ClientByPhone`,
    headers: HEADERS,
  },
  fetchClientsTable: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/ClientList`,
    headers: HEADERS,
  },
  fetchOneClientDischargeByClientId: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/DischargeInfo`,
    headers: HEADERS,
  },
  dischargeClient: {
    method: 'post',
    url: `${BASE_URL}/api/ce/yp02/yappy/Discharge`,
    headers: HEADERS,
  },
};
