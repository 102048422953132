export interface IClientTableQuery {
  dateLastModifiedStart?: Date;
  dateLastModifiedEnd?: Date;
  status: EClientStatus | null;
}

export interface IClientTablePagination {
  page: number;
  pageSize: number;
}

export interface IClientTableSorting {
  field: 'dateOfLastModification';
  sort: 'ASC' | 'DESC';
}

export interface IClientTable {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  status: EClientStatus;
  dateOfLastModification: Date;
  entemis: string;
}

export interface IClientDetail extends IClientTable {
  bank: string;
  accountNumber: string;
  dateOfAffiliation: Date;
}

export interface IClientDischarge {
  id: string;
  clientId: string;
  dischargeDate: Date;
  dischargedBy: string;
  dischargeReason: EDischargeMotive;
  dischargeDetails: string;
}

export enum EDischargeMotive {
  AffiliationError = 'affiliationError',
  Death = 'death',
  Fraud = 'fraud',
  Other = 'other',
}

export interface IClientsTableResponse {
  total: number;
  clients: IClientTable[];
}

export enum EClientStatus {
  Active = 'active',
  Discharged = 'discharged',
}

export interface IClientDischargeDto {
  clientId: string;
  dischargeReason: EDischargeMotive;
  dischargeDetails: string;
}
