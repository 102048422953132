import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState, IAsyncAction } from 'src/app/lib/typings';
import {
  REGEX_ACCEPTABLE_ALPHANUMERIC_DATA,
  REGEX_ACCEPTABLE_INPUT_PHONE_NUMBER,
  REGEX_COMPLETE_PHONE_NUMBER,
  REGEX_TO_ADD_HYPHEN_TO,
} from '../constants';
import { clientsCompletePhoneInput } from '../redux/clients.actions';
import { selectFromClientsStore } from '../redux/clients.selectors';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[yappyPhoneSearch]',
})
export class YappyPhoneSearchDirective implements OnInit, OnDestroy {
  fetchClientDetailAction$!: Observable<IAsyncAction>;
  private fetchDetailSub!: Subscription;
  private lastAcceptableInput = ''; // * auxiliario

  @Output() yappyOnLoadingChange = new EventEmitter<boolean>();
  @Output() yappyOnError = new EventEmitter<boolean>();

  constructor(
    private readonly store: Store<IAppState>,
    private el: ElementRef<HTMLInputElement>
  ) {}

  @HostListener('input', ['$event']) handleChange(event: KeyboardEvent): void {
    // * an empty string has to be considered acceptable othwerwise the user won't
    // * be able to clear the input
    let value = (event.target as HTMLInputElement).value;
    const isAcceptablePhoneNumber = REGEX_ACCEPTABLE_INPUT_PHONE_NUMBER.test(
      value
    );
    const isAcceptable =
      !value ||
      isAcceptablePhoneNumber ||
      REGEX_ACCEPTABLE_ALPHANUMERIC_DATA.test(value);

    if (isAcceptable) {
      if (isAcceptablePhoneNumber) {
        const hasToBeAddedHyphenTo = REGEX_TO_ADD_HYPHEN_TO.test(value);
        if (hasToBeAddedHyphenTo) {
          value = this.addHyphen(value);
        }
      }

      this.lastAcceptableInput = value; // * purposely remembering AFTER possibly adding hyphen
      this.el.nativeElement.value = value;
      const isComplete = REGEX_COMPLETE_PHONE_NUMBER.test(value);
      if (isComplete) {
        // ACCEPTABLE AND COMPLETE
        this.store.dispatch(clientsCompletePhoneInput({ phone: value }));
      } else {
        // ACCEPTABLE BUT INCOMPLETE
      }
    } else {
      // UNACCEPTABLE
      this.el.nativeElement.value = this.lastAcceptableInput;
    }
  }

  ngOnInit(): void {
    this.fetchClientDetailAction$ = this.store.select(
      selectFromClientsStore('actionFetchDetail')
    );

    this.fetchDetailSub = this.fetchClientDetailAction$.subscribe(
      ({ isLoading, error }) => {
        this.el.nativeElement.disabled = isLoading;
        this.yappyOnLoadingChange.emit(isLoading);
        if (error) {
          this.yappyOnError.emit(true);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.fetchDetailSub.unsubscribe();
  }

  private addHyphen(phone: string): string {
    return `${phone.slice(0, 4)}-${phone.slice(4)}`;
  }
}
