import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { IAppState } from 'src/app/lib/typings';
import {
  clientsTableClearQueryClicked,
  clientsTableQueryChange,
} from '../../redux/clients.actions';
import {
  selectFromClientsStore,
  selectIsDefaultQuerySelected,
} from '../../redux/clients.selectors';
import { EClientStatus, IClientTableQuery } from '../../typings';

@Component({
  selector: 'app-clients-table-query',
  templateUrl: './clients-table-query.component.html',
  styleUrls: ['./clients-table-query.component.scss'],
})
export class ClientsTableQueryComponent implements OnInit, OnDestroy {
  query$!: Observable<IClientTableQuery>;
  selectIsDefaultQuerySelected$!: Observable<boolean>;
  dateRanges: { [key: string]: [Date, Date] };
  dateRangeTuple: [Date, Date] | [];
  dateRangeSub: Subscription;
  private readonly currentDate = new Date();

  constructor(private readonly store: Store<IAppState>) {
    this.dateRanges = {
      Hoy: this.ensureDateTupleIsBoundToCorrectDays([
        startOfDay(this.currentDate),
        endOfDay(this.currentDate),
      ]),
      'Esta semana': this.ensureDateTupleIsBoundToCorrectDays([
        startOfWeek(this.currentDate),
        endOfWeek(this.currentDate),
      ]),
      'Este mes': this.ensureDateTupleIsBoundToCorrectDays([
        startOfMonth(this.currentDate),
        endOfMonth(this.currentDate),
      ]),
      'Este cuarto': this.ensureDateTupleIsBoundToCorrectDays([
        startOfQuarter(this.currentDate),
        endOfQuarter(this.currentDate),
      ]),
      'Este año': this.ensureDateTupleIsBoundToCorrectDays([
        startOfYear(this.currentDate),
        endOfYear(this.currentDate),
      ]),
    };
  }

  ngOnInit(): void {
    this.query$ = this.store.select(selectFromClientsStore('query'));
    this.selectIsDefaultQuerySelected$ = this.store.select(
      selectIsDefaultQuerySelected
    );

    this.dateRangeSub = this.query$.subscribe(
      ({ dateLastModifiedStart, dateLastModifiedEnd }) => {
        // * !XOR
        if (!dateLastModifiedStart && !dateLastModifiedEnd) {
          this.dateRangeTuple = [];
        }

        if (dateLastModifiedStart && dateLastModifiedEnd) {
          this.dateRangeTuple = [dateLastModifiedStart, dateLastModifiedEnd];
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.dateRangeSub.unsubscribe();
  }

  handleStatusChange(value: EClientStatus): void {
    this.store.dispatch(clientsTableQueryChange({ query: { status: value } }));
  }

  handleDatesRangeChange(dates: Date[]): void {
    if (dates.length === 2 || !dates.length) {
      this.store.dispatch(
        clientsTableQueryChange({
          query: {
            dateLastModifiedStart: dates[0],
            dateLastModifiedEnd: dates[1],
          },
        })
      );
    }
  }

  handleClearFilter(e: Event): void {
    e.preventDefault();
    this.store.dispatch(clientsTableClearQueryClicked());
  }

  private ensureDateTupleIsBoundToCorrectDays([start, end]: [Date, Date]): [
    Date,
    Date
  ] {
    return [this.ensureStartOfDay(start), this.ensureEndOfDay(end)];
  }

  private ensureStartOfDay(date: Date): Date {
    return new Date(date.setUTCHours(0));
  }

  private ensureEndOfDay(date: Date): Date {
    return sub(date, { hours: 5 }); // for some reason the end dates are being generated 5 hours after
    // the supposed end of day
  }
}
