import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsTablePageComponent } from './pages/clients-table-page/clients-table-page.component';
import { ClientsDetailPageComponent } from './pages/clients-detail-page/clients-detail-page.component';
import { ClientsHeaderComponent } from './layout/clients-header/clients-header.component';
import { ClientsRootLayoutComponent } from './layout/clients-root-layout/clients-root-layout.component';
import { ClientsPhoneSearchComponent } from './components/clients-phone-search/clients-phone-search.component';
import { ClientsEffects } from './redux/clients.effects';
import { clientsReducer } from './redux/clients.reducer';
import { NGZorroModule } from '../ng-zorro.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClientsTableComponent } from './components/clients-table/clients-table.component';
import { ClientsTableQueryComponent } from './components/clients-table-query/clients-table-query.component';
import { ClientsTableHeaderComponent } from './components/clients-table-header/clients-table-header.component';
import { ClientsSingleSearchPageComponent } from './pages/clients-single-search-page/clients-single-search-page.component';
import { YappyPhoneSearchDirective } from './lib/yappy-phone-search.directive';
import { PhoneFormatPipe } from './lib/phone-format.pipe';

@NgModule({
  declarations: [
    ClientsTablePageComponent,
    ClientsDetailPageComponent,
    ClientsHeaderComponent,
    ClientsRootLayoutComponent,
    ClientsPhoneSearchComponent,
    ClientsTableComponent,
    ClientsTableQueryComponent,
    ClientsTableHeaderComponent,
    ClientsSingleSearchPageComponent,
    YappyPhoneSearchDirective,
    PhoneFormatPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ClientsRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature('clients', clientsReducer),
    EffectsModule.forFeature([ClientsEffects]),
    NGZorroModule,
    FormsModule,
    ScrollingModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSortModule,
  ],
})
export class ClientsModule {}
