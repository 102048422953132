import { Injectable } from '@angular/core';
import {
  CLIENT_STATUS_TO_WORD_MAP,
  DISCHARGE_MOTIVE_SPANISH_WORD_MAP,
} from '../constants';
import { EClientStatus, EDischargeMotive } from '../typings';

@Injectable({
  providedIn: 'root',
})
export class ClientsModelViewUtilService {
  constructor() {}

  getStatusWord(status: EClientStatus): string {
    return CLIENT_STATUS_TO_WORD_MAP[status];
  }

  getStatusIndicatorUrl(status: EClientStatus): string {
    return status === EClientStatus.Active
      ? 'assets/status-activo.png'
      : 'assets/status-de-baja.png';
  }

  getDischargeMotiveWord(motive: EDischargeMotive): string {
    return DISCHARGE_MOTIVE_SPANISH_WORD_MAP[motive];
  }

  getDischargeMotivesEntries(): [EDischargeMotive, string][] {
    return Object.entries(DISCHARGE_MOTIVE_SPANISH_WORD_MAP) as [
      EDischargeMotive,
      string
    ][];
  }
}
